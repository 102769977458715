<template>
  <div>
    <b-row>
      <b-col cols="10">
        <h1 class="d-inline mr-3">Bot Configurations</h1>
        <bot-config-activity-log class="align-top pt-3" :botId="formItem.botId" />
      </b-col>
      <b-col cols="3" class="mt-sm-3 mb-4">
        <bot-selector @botChanged="changeBot" />
      </b-col>
    </b-row>

    <b-form ref="main-form" @submit.stop.prevent="promptConfirm">
      <BotConfigs :botMarioConfig="formItem" />
      <!--<b-row>
          <b-col cols="12" xl="8">-->
              <!-- Section 1: General Information -->
              <!--<h5 class="mt-4 mb-3 text-primary">Bot Information</h5>
              <b-row class="my-2">
                  <b-col cols="12" xl="3">
                      <label>Url Path</label>
                  </b-col>
                  <b-col cols="12" xl="9">
                      <b-input class="form-control-sm" v-model.trim="formItem.urlPath" />
                  </b-col>
              </b-row>
              <b-row class="my-2">
                  <b-col cols="12" xl="3">
                      <label>Db Profile</label>
                  </b-col>
                  <b-col cols="12" xl="9">
                      <b-input class="form-control-sm" v-model.trim="formItem.dbProfile" />
                  </b-col>
              </b-row>
              <b-row class="my-2">
                  <b-col cols="12" xl="3">
                      <label>Client</label>
                  </b-col>
                  <b-col cols="12" xl="9">
                      <b-input class="form-control-sm" v-model.trim="formItem.client" />
                  </b-col>
              </b-row>
              <b-row class="my-2">
                  <b-col cols="12" xl="3">
                      <label>Title</label>
                  </b-col>
                  <b-col cols="12" xl="9">
                      <b-input class="form-control-sm" v-model.trim="formItem.title" />
                  </b-col>
              </b-row>
              <b-row class="my-2">
                  <b-col cols="12" xl="3">
                      <label>Api Paths</label>
                  </b-col>
                  <b-col cols="12" xl="9">
                      <b-input class="form-control-sm"
                               v-model.trim="formItem.apiPaths">
                      </b-input>
                  </b-col>
              </b-row>
              <b-row class="my-2">
                  <b-col cols="12" xl="3">
                      <label>Plugin Paths</label>
                  </b-col>
                  <b-col cols="12" xl="9">
                      <b-input class="form-control-sm"
                               v-model.trim="formItem.pluginPaths">
                      </b-input>
                  </b-col>
              </b-row>
              <b-row class="my-2">
                  <b-col cols="12" xl="3">
                      <label>Socket Paths</label>
                  </b-col>
                  <b-col cols="12" xl="9">
                      <b-input class="form-control-sm"
                               v-model.trim="formItem.socketPaths">
                      </b-input>
                  </b-col>
              </b-row>
              <b-row class="my-2">
                  <b-col cols="12" xl="3">
                      <label>Access Tokens</label>
                  </b-col>
                  <b-col cols="12" xl="9">
                      <b-input class="form-control-sm"
                               v-model.trim="formItem.accessToken">
                      </b-input>
                  </b-col>
              </b-row>
              <b-row class="my-2">
                  <b-col cols="12" xl="3">
                      <label>Brand Id</label>
                  </b-col>
                  <b-col cols="12" xl="9">
                      <b-input class="form-control-sm" v-model.trim="formItem.brandId" />
                  </b-col>
              </b-row>-->

              <!-- Section 2: API Details -->
              <!--<h5 class="mt-4 mb-3 text-primary">Live Chat Settings</h5>
              <b-row class="my-2">
                  <b-col cols="12" xl="3">
                      <label>Salesforce Url</label>
                  </b-col>
                  <b-col cols="12" xl="9">
                      <b-input class="form-control-sm" v-model.trim="formItem.salesforceUrl" />
                  </b-col>
              </b-row>
              <b-row class="my-2">
                  <b-col cols="12" xl="3">
                      <label>GBQ Api Url</label>
                  </b-col>
                  <b-col cols="12" xl="9">
                      <b-input class="form-control-sm" v-model.trim="formItem.gbqApiUrl" />
                  </b-col>
              </b-row>
              <b-row class="my-2">
                  <b-col cols="12" xl="3">
                      <label>GBQ Api Authentication</label>
                  </b-col>
                  <b-col cols="12" xl="9">
                      <b-input class="form-control-sm" v-model.trim="formItem.gbqApiAuthentication" />
                  </b-col>
              </b-row>
              <b-row class="my-2">
                  <b-col cols="12" xl="3">
                      <label>X-Api Key</label>
                  </b-col>
                  <b-col cols="12" xl="9">
                      <b-input class="form-control-sm"
                               v-model.trim="formItem.xApiKey">
                      </b-input>
                  </b-col>
              </b-row>
              <b-row class="my-2">
                  <b-col cols="12" xl="3">
                      <label>Organisation Id</label>
                  </b-col>
                  <b-col cols="12" xl="9">
                      <b-input class="form-control-sm"
                               v-model.trim="formItem.organisationId">
                      </b-input>
                  </b-col>
              </b-row>
              <b-row class="my-2">
                  <b-col cols="12" xl="3">
                      <label>Deployment Id</label>
                  </b-col>
                  <b-col cols="12" xl="9">
                      <b-input class="form-control-sm"
                               v-model.trim="formItem.deploymentId">
                      </b-input>
                  </b-col>
              </b-row>
              <b-row class="my-2">
                  <b-col cols="12" xl="3">
                      <label>Version</label>
                  </b-col>
                  <b-col cols="12" xl="9">
                      <b-input class="form-control-sm"
                               v-model.trim="formItem.version">
                      </b-input>
                  </b-col>
              </b-row>
              <b-row class="my-2">
                  <b-col cols="12" xl="3">
                      <label>Affinity</label>
                  </b-col>
                  <b-col cols="12" xl="9">
                      <b-input class="form-control-sm"
                               v-model.trim="formItem.affinity">
                      </b-input>
                  </b-col>
              </b-row>
              <b-row class="my-2">
                  <b-col cols="12" xl="3">
                      <label>Agent Reconnect Buffer</label>
                  </b-col>
                  <b-col cols="12" xl="9">
                      <b-input class="form-control-sm"
                               v-model.trim="formItem.agentReconnectBuffer">
                      </b-input>
                  </b-col>
              </b-row>
              <b-row class="my-2">
                  <b-col cols="12" xl="3">
                      <label>Kzing Brand</label>
                  </b-col>
                  <b-col cols="12" xl="9">
                      <b-input class="form-control-sm"
                               v-model.trim="formItem.kzingBrand">
                      </b-input>
                  </b-col>
              </b-row>
              <b-row class="my-2">
                  <b-col cols="12" xl="3">
                      <label>Livechat Values</label>
                  </b-col>
                  <b-col cols="12" xl="9">
                      <default-values v-model="formItem.defaultValues"/>
                  </b-col>
              </b-row>
              <b-row class="my-2">
                  <b-col cols="12" xl="3">
                      <label>Brand Market</label>
                  </b-col>
                  <b-col cols="12" xl="9">
                      <b-input class="form-control-sm"
                               v-model.trim="formItem.brandMarket">
                      </b-input>
                  </b-col>
              </b-row>
              <b-row class="my-2">
                  <b-col cols="12" xl="3">
                      <label>Brand Name</label>
                  </b-col>
                  <b-col cols="12" xl="9">
                      <b-input class="form-control-sm"
                               v-model.trim="formItem.brandName">
                      </b-input>
                  </b-col>
              </b-row>
              <b-row class="my-2">
                  <b-col cols="12" xl="3">
                      <label>Customer</label>
                  </b-col>
                  <b-col cols="12" xl="9">
                      <b-input class="form-control-sm"
                               v-model.trim="formItem.customer">
                      </b-input>
                  </b-col>
              </b-row>
              <b-row class="my-2">
                  <b-col cols="12" xl="3">
                      <label>Mobile Api Type</label>
                  </b-col>
                  <b-col cols="12" xl="9">
                      <b-input class="form-control-sm"
                               v-model.trim="formItem.mobileApiType">
                      </b-input>
                  </b-col>
              </b-row>
              <b-row class="my-2">
                  <b-col cols="12" xl="3">
                      <label>Message</label>
                  </b-col>
                  <b-col cols="12" xl="9">
                      <custom-values v-model="formItem.message" />
                  </b-col>
              </b-row>
              <b-row class="my-2">
                  <b-col cols="12" xl="3">
                      <label>Mobile Api Type</label>
                  </b-col>
                  <b-col cols="12" xl="9">
                      <v-json-editor v-model="formItem.mobileApiDetail"
                                     :options="{ mode: 'code', mainMenuBar: false }" />
                  </b-col>
              </b-row>
          </b-col>
      </b-row>-->
    </b-form>

    <b-row class="mb-5">
      <b-col cols="12" class="d-flex">
        <b-button class="ml-auto" variant="primary" @click="promptConfirm"
          >Submit
        </b-button>
      </b-col>
    </b-row>
    <div v-if="isBusy" class="spinner-busy">
      <b-spinner class="align-middle"></b-spinner>
      <strong>Loading...</strong>
    </div>
  </div>
</template>

<script>
import httpclient from '@/mixins/HttpHandler';
//import CustomValues from '@/components/Intents/CustomValues';
import BotSelector from '@/components/UI/BotSelector';
import BotConfigActivityLog from '@/components/Bots/BotConfigActivityLog';
//import VJsonEditor from '@/components/UI/JsonEditor';
//import DefaultValues from '@/components/Intents/DefaultValues';
import BotConfigs from '@/components/Bots/BotConfigurations';
//import DefaultValues from '@/components/Bots/DefaultValues';

export default {
  /*global _*/
  mixins: [httpclient],
  components: {
    BotSelector,
    //CustomValues,
    BotConfigActivityLog,
    //VJsonEditor,
    //DefaultValues,
    BotConfigs
  },
  mounted() {
    const botId =
      Number(this.$route.params.id) || this.$store.state.selectedBotId;
      if (botId) this.changeBot(botId);
  },
  data() {
    return {
      path: 'bot-configs',
      bots: [],
      formItem: {
        botId: -1,
        acctId: -1,
        urlPath: '',
        dbProfile: '',
        client: '',
        title: '',
        apiPaths: '',
        pluginPaths: '',
        socketPaths: '',
        accessToken: '',
        brandId: '',
        salesforceUrl: '',
        gbqApiUrl: '',
        gbqApiAuthentication: '',
        xApiKey: '',
        organisationId: '',
        deploymentId: '',
        version: '',
        affinity: '',
        agentReconnectBuffer: '',
        kzingBrand: '',
        defaultValues: '',
        brandMarket: '',
        brandName: '',
        customer: '',
        mobileApiType: '',
        message: '',
        mobileApiDetail: {}
      }
    };
  },
  watch: {},
  methods: {
    getData() {},
    changeBot(botId) {
      if (botId) {
        this.formItem.botId = botId;
      }

      this.httpclient.get('api/BotMarioConfigs/' + this.formItem.botId).then((resp) => {
        if (resp.data) {
            const formItem = _.cloneDeep(resp.data);
            if (formItem.defaultValues) {
                let parsedValues = JSON.parse(formItem.defaultValues);
                /*formItem.defaultValues = Object.entries(parsedValues).map(([key, values]) => {
                    return {
                        key: key,
                        value: values.join(", "),
                    };
                });*/
                const defaultEntry = parsedValues.Default
                    ? { key: "Default", value: parsedValues.Default.join(", ") }
                    : null;

                // Other entries
                const otherEntries = Object.entries(parsedValues)
                    .filter(([key]) => key !== "Default")
                    .map(([key, values]) => ({
                        key,
                        value: values.join(", "),
                    }));

                // Combine into a proper array
                formItem.defaultValues = defaultEntry
                    ? [defaultEntry, ...otherEntries]
                    : otherEntries;
            } else {
                formItem.defaultValues = [];
            }

            if (formItem.message) {
                let customObj = JSON.parse(formItem.message);
                if (_.isObject(customObj) && !_.isArray(customObj)) {
                    let message = [];
                    _.forEach(customObj, function (value, key) {
                        message.push({ key: key, value: value });
                    });
                    formItem.message = message || [];
                } else {
                    formItem.message = [];
                }
            } else {
                formItem.message = [];
            }

            formItem.mobileApiDetail = JSON.parse(formItem.mobileApiDetail) || {};

            if (Array.isArray(formItem.socketPaths)) {
                formItem.socketPaths = formItem.socketPaths.join(", "); // Convert array to a comma-separated string for display
            }
            if (Array.isArray(formItem.kzingBrand)) {
                formItem.kzingBrand = formItem.kzingBrand.join(", "); // Convert array to a comma-separated string for display
            }

            this.formItem = formItem;
        } else {
            // send default value when db no data
            const formItem = {
              botId: this.formItem.botId,
              acctId: -1,
              urlPath: '',
              dbProfile: '',
              client: '',
              title: '',
              apiPaths: '',
              pluginPaths: '',
              socketPaths: '',
              accessToken: '',
              brandId: '',
              salesforceUrl: '',
              gbqApiUrl: '',
              gbqApiAuthentication: '',
              xApiKey: '',
              organisationId: '',
              deploymentId: '',
              version: '',
              affinity: '',
              agentReconnectBuffer: '',
              kzingBrand: '',
              defaultValues: [],
              brandMarket: '',
              brandName: '',
              customer: '',
              mobileApiType: '',
              message: [],
              mobileApiDetail: {}
            };
            this.formItem = formItem;
        }
      });
    },
    promptConfirm() {
      const vm = this;

      const confirmMessage = 'Update the bot config settings?';

      this.$bvModal
        .msgBoxConfirm(confirmMessage, { centered: true })
        .then(function (confirm) {
          if (confirm) {
            vm.editItem();
          } else {
            vm.$refs['form-modal'].show();
          }
        });
    },
    editItem() {
      const formItem = _.cloneDeep(this.formItem);
      formItem.defaultValues = formItem.defaultValues.reduce((acc, { key, value }) => {
        // Only include non-empty keys and values
        if (key && value) {
          if (!acc[key]) {
            acc[key] = [];
          }
        //acc[key].push(value);  // Add the value to the respective array
        acc[key].push(...value.split(',').map((v) => v.trim()));
        }
        return acc;
      }, {});

      formItem.defaultValues = JSON.stringify(formItem.defaultValues);

      formItem.mobileApiDetail = JSON.stringify(formItem.mobileApiDetail);

      let customObj = _.reduce(
        formItem.message,
        (obj, { key, value }) => ({ ...obj, [key]: value }),
        {}
      );
      formItem.message = JSON.stringify(customObj);

      // Ensure kzingBrand is parsed back into an array
    if (typeof formItem.kzingBrand === 'string') {
        formItem.kzingBrand = formItem.kzingBrand
            .split(",") // Split string by commas
            .map(item => item.trim()) // Trim whitespace from each entry
            .filter(item => item); // Remove empty entries
    }

    // Ensure socketPaths is parsed back into an array
    if (typeof formItem.socketPaths === 'string') {
        formItem.socketPaths = formItem.socketPaths
            .split(",") // Split string by commas
            .map(item => item.trim()) // Trim whitespace from each entry
            .filter(item => item); // Remove empty entries
    }


      this.httpclient.post('api/BotMarioConfigs', formItem).then(() => {
        this.$bvModal
          .msgBoxOk('Bot config settings had been updated sucessfully', {
            okVariant: 'success',
          })
          .then(() => {
            this.changeBot();
          });
      });
    },
  },
};

</script>

<style lang="scss" scoped></style>
