<template>
  <b-row class="mb-4">
    <b-col v-for="(config, index) in advancedFields" :key="index" cols="12">
      <div class="form-group">
        <label class="form-label">{{ config.label }}</label>

        <!-- Render input for standard fields -->
        <b-input
          v-if="!config.component"
          v-model.trim="botMarioConfig[config.field]"
          :type="config.type || 'text'"
          class="form-control"
          :placeholder="config.placeholder || ''"
          size="sm"
          :name="config.field"
          required
        />

        <!-- Render custom component if defined -->
        <component
          v-else
          :is="config.component"
          v-model="botMarioConfig[config.field]"
          v-bind="config.props"
          class="custom-component"
          :name="config.field"
          required
        />
        <hr v-if="config.field === 'brandId'" class="mt-4 mb-4" />
      </div>
    </b-col>
  </b-row>
</template>

<script>
  import CustomValues from '@/components/Intents/CustomValues';
  import VJsonEditor from '@/components/UI/JsonEditor';

  export default {
    components: {
      CustomValues,
      VJsonEditor,
    },
    props: {
      botMarioConfig: { type: Object, required: true },
    },
    data() {
      return {
        advancedFields: [
          // Fields from the second template
              { label: "Url Path", field: "urlPath", type: "url", placeholder: "https://staging-app.bimariobot.com/strawberryapp", required: true },
          { label: "Db Profile", field: "dbProfile", placeholder: "mario.staging.1", required: true },
          { label: "Client", field: "client", placeholder: "strawberryapp", required: true },
          { label: "Title", field: "title", placeholder: "Bot A", required: true },
          { label: "Api Paths", field: "apiPaths", type: "url", placeholder: "https://staging-app.bimariobot.com/nodeApi", required: true },
          { label: "Plugin Paths", field: "pluginPaths", type: "url", placeholder: "https://staging-app.bimariobot.com/plugin", required: true },
          { label: "Socket Paths", field: "socketPaths", placeholder: "wss://staging-bot.bimariobot.com/socket", required: true },
          { label: "Access Token", field: "accessToken", placeholder: "aWdTYlR7ImNpcGhlcl90ZXh0IjogIkxPalB2RHZaSzIrZFEx==", required: true },
          { label: "Brand", field: "brandId", placeholder: "welton, vpo, masuta", required: true },
          // Fields from the first template
          { label: "Salesforce Url", field: "salesforceUrl", type: "url", placeholder: "https://123abc.salesforceliveagent.com", required: true },
          { label: "GBQ Api Url", field: "gbqApiUrl", type: "url", placeholder: "https://api123-staging.wzryjc.cc", required: true },
          { label: "GBQ Api Authentication", field: "gbqApiAuthentication", placeholder: "abc1234==", required: true },
          { label: "X-Api Key", field: "xApiKey", placeholder: "vuh123343ewds", required: true },
          { label: "Organisation Id", field: "organisationId", placeholder: "123abc", required: true },
          { label: "Deployment Id", field: "deploymentId", placeholder: "123abc", required: true },
          { label: "Version", field: "version", placeholder: "49", required: true },
          { label: "Affinity", field: "affinity", placeholder: "null", required: true },
          { label: "Agent Reconnect Buffer", field: "agentReconnectBuffer", placeholder: "1 * 60", required: true },
          { label: "Kzing Brand", field: "kzingBrand", placeholder: "1,2,3,4", required: true },
          {
            label: "Livechat Values",
            field: "defaultValues",
            component: "CustomValues",
            props: { required: true },
          },
          { label: "Brand Market", field: "brandMarket", placeholder: "Fun88", required: true },
          { label: "Brand Name", field: "brandName", placeholder: "fun88", required: true },
          { label: "Customer", field: "customer", placeholder: "fun88", required: true },
          { label: "Mobile Api Type", field: "mobileApiType", placeholder: "Fun88", required: true },
          {
            label: "Message",
            field: "message",
            component: "CustomValues",
            props: { required: true },
          },
          {
            label: "Mobile Api Detail",
            field: "mobileApiDetail",
            component: "VJsonEditor",
            props: {
              options: { mode: "code", mainMenuBar: false },
            },
          },
        ],
      };
    },
  };
</script>
